.event-slideshow {
    margin-top: 15%;
    width: 90%; /* Verkleinere die Breite der Slideshow */
    max-width: 1200px; /* Optional: Setze eine maximale Breite für größere Bildschirme */
    overflow: hidden;
    z-index: -1;
    margin-left: auto; /* Zentriert die Slideshow */
    margin-right: auto; /* Zentriert die Slideshow */
    border-radius: 15px;
}

.slideshow {
    display: flex;
    transition: transform 1.2s ease;
    position: relative;
    width: 300%; /* Reduziere dies weiter, falls du mehr oder weniger Bilder hast */
    height: auto;
}

.slideshow-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    z-index: -1;
    filter: brightness(80%);
}
