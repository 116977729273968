@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --white: #fff;
  --white-100: #f9f9f9;
  --white-200: #f0f0f0;
  --white-300: #ebebeb;
  --white-400: #e4e4e4;
  --white-500: #dddddd;
  --white-600: #d6d6d6;
  --white-700: #cfcfcf;
  --white-800: #c8c8c8;
  --white-900: #c1c1c1;

  --red: #DB3546;
  --red-100: #fde4e8;
  --red-200: #f8bcc1;
  --red-300: #f2969b;
  --red-400: #ec7074;
  --red-500: #e64a4e;
  --red-600: #cf3641;
  --red-700: #b32d37;
  --red-800: #96252d;
  --red-900: #791c23;

  --blue: #027BFF;
  --blue-100: #dceeff;
  --blue-200: #b8ddff;
  --blue-300: #94ccff;
  --blue-400: #70bbff;
  --blue-500: #4caaee;
  --blue-600: #3d8dcc;
  --blue-700: #2f70aa;
  --blue-800: #205387;
  --blue-900: #113664;

  --orange: #ffa500;
  --orange-100: #fff4e0;
  --orange-200: #ffe4b8;
  --orange-300: #ffd390;
  --orange-400: #ffc368;
  --orange-500: #ffb340;
  --orange-600: #e69d36;
  --orange-700: #cc872d;
  --orange-800: #b37123;
  --orange-900: #995a1a;

  --yellow: #ffc107;
  --yellow-100: #fff7db;
  --yellow-200: #ffeead;
  --yellow-300: #ffe67f;
  --yellow-400: #ffdd51;
  --yellow-500: #ffd423;
  --yellow-600: #e6bf1f;
  --yellow-700: #cca91b;
  --yellow-800: #b39416;
  --yellow-900: #997e12;

  --green: #28a745;
  --green-100: #e7f5eb;
  --green-200: #c4e4cd;
  --green-300: #a1d4af;
  --green-400: #7ec491;
  --green-500: #5bb473;
  --green-600: #4e9e63;
  --green-700: #418754;
  --green-800: #346043;
  --green-900: #274932;

  --gray: #6c757d;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;

  --black: #000;
  --black-100: #333333;
  --black-200: #262626;
  --black-300: #1f1f1f;
  --black-400: #191919;
  --black-500: #141414;
  --black-600: #0f0f0f;
  --black-700: #0a0a0a;
  --black-800: #050505;
  --black-900: #000000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white-200);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
