body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

main {
  padding: 20px;
  text-align: center;
}

#welcome_label {
  font-size: 6em;
  color: #fff;
  margin-top: 16.3%;
  margin-bottom: -35%;
  position: relative;
  z-index: 45;
}

main {
  padding: 0%;
}