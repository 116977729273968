.events {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.event {
    display: flex;
    align-items: flex-start;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    gap: 3em;
    width: 100%;
    border: 1px solid darkgray;
    border-radius: 28px;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    max-height: 40vh;
}

.event img {
    max-width: 350px;
    height: auto;
}

.event-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    padding-right: 5%;
}

.event-details h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 35px;
}

.event-details p {
    font: 1em sans-serif;
    margin: 10px 0;
    text-align: center;
    justify-content: center;
}

#font_size_1 {
    font: 3em sans-serif;
    font-weight: bold;
}

#font_size_2 {
    font: 1.5em sans-serif;
}

#font_size_3 {
    font: 1em sans-serif;
}
