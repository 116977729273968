.organizer-view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    width: 90%;
    max-width: 1200px;
    box-sizing: border-box;
}

.grid-item {
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

.user-list {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.event-calendar {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.venue-map {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.extra-info {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}